import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteProperty } from 'services/applications.service';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';

// components
import PropertyModal from './components/PropertyModal';

// chakra
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	Button,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	useToast,
	Flex,
	Container,
	Stack,
	Text,
	Input,
	StackDivider,
	Image,
	Select,
	Spinner,
	Center,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const Properties = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const {
		properties,
		rejectedProperties,
		inReviewProperties,
		acceptedProperties,
	} = useSelector((state) => state.user);

	const route = useLocation();

	const [selectedProperty, setSelectedProperty] = useState(null);
	const [propertiesList, setPropertiesList] = useState([]);

	useEffect(() => {
		if (
			route.pathname === '/admin/properties/properties-all' ||
			route.pathname === '/admin/properties'
		) {
			setPropertiesList(properties);
		} else if (route.pathname === '/admin/properties/properties-rejected') {
			setPropertiesList(rejectedProperties);
		} else if (
			route.pathname === '/admin/properties/properties-in-review'
		) {
			setPropertiesList(inReviewProperties);
		} else if (route.pathname === '/admin/properties/properties-accepted') {
			setPropertiesList(acceptedProperties);
		}
	}, [
		route.pathname,
		properties,
		rejectedProperties,
		inReviewProperties,
		acceptedProperties,
	]);

	return (
		<Fragment>
			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<Box
					bg="white"
					rounded="lg"
					p="4"
				>
					<TableContainer>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th>#</Th>
									<Th>Address</Th>
									<Th>Status</Th>
									<Th>Time Submitted</Th>
									<Th>User Type</Th>
									<Th>User Email</Th>
									<Th>Action</Th>
								</Tr>
							</Thead>

							<Tbody>
								{propertiesList.map((property, index) => (
									<Tr>
										<Td>{index + 1}</Td>
										<Td>{property?.stepTwo[3]?.answer}</Td>
										<Td>{property?.status}</Td>
										<Td>
											{moment
												.tz(
													property?.timeSubmitted?.toDate(),
													'America/New_York'
												)
												.format('MM/DD/YYYY hh:mm A')}
										</Td>
										<Td>{property?.userData?.type}</Td>
										<Td>{property?.userData?.email}</Td>
										<Td>
											<Flex spacing={5}>
												<Button
													mr="4"
													onClick={() => {
														setSelectedProperty(
															property
														);
														onOpen();
													}}
												>
													Edit
												</Button>
												<Button
													colorScheme="red"
													onClick={() => {
														setSelectedProperty(
															property
														);
														onDeleteOpen();
													}}
												>
													Delete
												</Button>
											</Flex>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
			</Box>

			{selectedProperty && (
				<PropertyModal
					isOpen={isOpen}
					onClose={onClose}
					selectedProperty={selectedProperty}
					setSelectedProperty={setSelectedProperty}
				/>
			)}

			<Modal
				isOpen={isDeleteOpen}
				onClose={() => {
					setSelectedProperty(null);
					onDeleteClose();
				}}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						Delete Property: {selectedProperty?.stepTwo[3]?.answer}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box>
							Are you sure you want to delete this property? You
							cannot undo this action.
						</Box>
					</ModalBody>

					<ModalFooter>
						<Button
							colorScheme="gray"
							mr={3}
							onClick={() => {
								setSelectedProperty(null);
								onDeleteClose();
							}}
						>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							onClick={async () => {
								await deleteProperty(selectedProperty?.id);
								setSelectedProperty(null);
								onDeleteClose();
								window.location.reload();
							}}
						>
							Delete
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Fragment>
	);
};

export default Properties;
